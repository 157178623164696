<template>
  <div>
    <b-modal
      v-model="display"
      hide-header
      hide-footer
      centered
      no-body
      body-class="p-0"
      dialog-class="modal-rem"
    >
      <template #default>
        <div class="modal-rem__content">
          <b-button
            class="modal-rem__content-close btn-icon"
            variant="flat-secondary"
            size="sm"
            @click="hide"
          >
            <feather-icon
              size="25"
              icon="XIcon"
            />
          </b-button>
          <div class="modal-rem__content-inner">
            <div class="modal-rem__content-title">
              Запомнить в 2-4 раза лучше уже на пробном занятии!
            </div>
            <div class="modal-rem__content-subtitle">
              Ваш ребенок узнает секрет суперпамяти на бесплатном уроке
            </div>
            <div class="modal-rem__content-listtitle">
              на курсах мы научим:
            </div>
            <ul class="modal-rem__content-list">
              <li class="modal-rem__list-item">
                Быстро и эффективно читать, навыку скорочтения
              </li>
              <li class="modal-rem__list-item">
                Легко и много запоминать, быстро воспринимать информацию
              </li>
              <li class="modal-rem__list-item">
                Технологиям быстрого обучения
              </li>
            </ul>
            <a
              class="modal-rem__content-btn"
              :href="umikumCabinetUrl"
              @click="showMore"
            >
              Вводный урок
              <feather-icon
                icon="ArrowRightIcon"
                class="ml-50"
                size="22"
              />
            </a>
            <div
              class="d-flex justify-content-center mt-1"
              style="max-width: 300px"
            >
              <a
                class="font-weight-bold font-medium-1"
                href="https://rupetitor.ru/courses.php"
                target="_blank"
              >
                Подробнее о курсе
              </a>
            </div>
            <div
              class="d-flex justify-content-center mt-1"
              style="max-width: 300px"
            >
              <b-form-group class="mb-0">
                <b-form-checkbox
                  v-model="dontShowAnymore"
                  class="mr-0 mt-50"
                  name="dontShowAnymore"
                  inline
                >
                  Больше не показывать
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <img
            class="modal-rem__content-mainimage"
            :src="require('@/assets/images/interactive/mainimage.png')"
            alt="image"
          >
          <div class="modal-rem__content-saleimage">
            <img
              :src="require('@/assets/images/interactive/sale.svg')"
              alt="image"
            >
            <p class="modal-rem__content-mainimage__header">
              Акция
            </p>
            <p class="modal-rem__content-mainimage__percent">
              -40%
            </p>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton, BFormCheckbox, BFormGroup, BModal,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import useJwt from '@/auth/jwt/useJwt'
import { studentRole, userRoles } from '../../store/user'

export default {
  name: 'InteractiveModal',
  components: {
    BModal, BButton, BFormGroup, BFormCheckbox,
  },
  data: () => ({
    display: false,
    dontShowAnymore: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isStudent() {
      return this.userRole === studentRole
    },
    umikumCabinetUrl() {
      const umikum = 'https://interactive.rupetitor.ru/kursmem'
      const accessToken = useJwt.getToken()
      return `${umikum}?accessToken=${accessToken}`
    },
  },
  mounted() {
    setTimeout(() => {
      if (!JSON.parse(localStorage.getItem('interactive-modal-disabled'))) this.display = true
    }, 400)
  },
  methods: {
    disableModal() {
      localStorage.setItem('interactive-modal-disabled', this.dontShowAnymore)
    },
    showMore() {
      this.disableModal()
      this.hide()
    },
    hide() {
      if (this.dontShowAnymore) this.disableModal()
      this.display = false
    },
  },
}
</script>

<style scoped lang="scss">
.modal-rem__content {
  width: 100%;
  max-width: 970px;
  padding: 60px 50px 70px;
  border-radius: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0.279px 3.99px 47.04px 1.96px rgba(131, 131, 131, 0.18);
}

.modal-rem__content-inner {
  position: relative;
  z-index: 3;
}

.modal-rem__content-title {
  font-size: 32px;
  color: rgb(48, 40, 75);
  font-weight: bold;
  line-height: 1.25;
  margin-bottom: 25px;
  max-width: 510px;
  width: 100%;
}

.modal-rem__content-subtitle {
  font-size: 17px;
  color: rgba(48, 40, 75, 0.631);
  line-height: 1.353;
  margin-bottom: 40px;
  max-width: 385px;
  width: 100%;
}

.modal-rem__content-listtitle {
  font-size: 13px;
  color: rgb(48, 40, 75);
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.modal-rem__content-list {
  list-style: none;
  margin-bottom: 35px;
  max-width: 385px;
  width: 100%;
  padding-inline-start: 0;
}

.modal-rem__list-item {
  font-size: 16px;
  color: rgba(48, 40, 75, 0.651);
  line-height: 1.438;
  position: relative;
  padding-left: 22px;
}

.modal-rem__list-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: rgb(125, 76, 252);
  width: 10px;
  height: 10px;
}

.modal-rem__content-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: rgb(125, 76, 252);
  width: 100%;
  max-width: 300px;
  height: 46px;
  padding: 12px 55px;
  position: relative;
  font-size: 15px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 1.333;
  transition: all 0.3s;
}

.modal-rem__content-btn:hover {
  opacity: 0.7;
}

.modal-rem__content-btn svg {
  position: absolute;
  right: 15px;

}

.modal-rem__content-mainimage {
  position: absolute;
  right: 90px;
  bottom: 115px;
  height: 310px;
  max-width: 550px;
  display: block;
}

.modal-rem__content-saleimage {
  position: absolute;
  right: 40px;
  top: 25px;
  height: 150px;
  max-width: 150px;
  display: block;

  img {
    height: 150px;
    max-width: 150px;
  }

  .modal-rem__content-mainimage__header {
    position: absolute;
    top: 41px;
    left: 42px;
    color: white;
    font-size: 18px;
  }

  .modal-rem__content-mainimage__percent {
    position: absolute;
    top: 71px;
    left: 28px;
    color: white;
    font-size: 33px;
    font-weight: bold;
  }
}

.modal-rem__content-close {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;
  z-index: 5;
}

@media screen and (max-width: 991px) {
  .modal-rem__content-saleimage {
    height: 190px;
    max-width: 180px;
  }

  .modal-rem__content-mainimage {
    height: 220px;
    max-width: 410px;
  }
}

@media screen and (max-width: 768px) {
  .modal-rem__content {
    padding: 30px 15px;
  }

  .modal-rem__content-list {
    max-width: 100%;
  }

  .modal-rem__content-title {
    max-width: 19rem;
    font-size: 27px;
  }

  .modal-rem__content-subtitle {
    max-width: 100%;
  }

  .modal-rem__content-saleimage {
    height: 190px;
    max-width: 180px;
    top: 50px;
    right: 10px!important;
  }

  .modal-rem__content-mainimage {
    display: none;
  }
}
</style>
