import Vue from 'vue'
import VueRouter from 'vue-router'
import useJwt from '@/auth/jwt/useJwt'
import { canNavigate } from '@/libs/acl/routeProtection'
import VueI18n from '@/libs/i18n'
import axiosIns from '@/libs/axios'
import store from '@/store'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      setTimeout(() => {
        const element = document.getElementById(to.hash.replace(/#/, ''))
        if (element && element.scrollIntoView) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      }, 500)
      return { selector: to.hash }
    }
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  },
  routes: [
    // Cabinet
    {
      path: '/',
      name: 'cabinet.dashboard',
      component: () => import('@/views/Dashboard.vue'),
      meta: {
        pageTitle: VueI18n.t('page.dashboard.title'),
        resource: 'dashboard',
      },
    },
    {
      path: '/stream',
      name: 'cabinet.stream',
      component: () => import('@/views/sessions/Sessions.vue'),
      meta: {
        pageTitle: 'Видеозвонок',
        resource: 'video-stream',
      },
    },
    {
      path: '/lessons',
      component: () => import('@/views/Courses.vue'),
      meta: {
        pageTitle: 'Уроки',
        resource: 'stream',
      },
      children: [
        {
          path: '',
          name: 'cabinet.sessions',
          component: () => import('@/views/video-stream/Lessons.vue'),
          meta: {
            pageTitle: 'Уроки',
            resource: 'video-stream',
          },
        },
        {
          path: 'online/:session_id',
          name: 'cabinet.sessions.online',
          component: () => import('@/views/video-stream/VideoStream.vue'),
          meta: {
            pageTitle: VueI18n.t('page.video-stream.title'),
            resource: 'video-stream',
            contentClass: 'video-stream-page',
          },
        },
        {
          path: 'add',
          name: 'cabinet.sessions.add',
          component: () => import('@/views/sessions/Session.vue'),
          meta: {
            pageTitle: 'Создание урока',
            resource: 'video-stream',
          },
        },
        {
          path: ':session_id',
          name: 'cabinet.session',
          component: () => import('@/views/sessions/Session.vue'),
          meta: {
            pageTitle: 'Редактирование урока',
            resource: 'video-stream',
          },
        },
      ],
    },
    {
      path: '/rooms/:room_id',
      name: 'cabinet.room',
      component: () => import('@/views/video-stream/VideoStream.vue'),
      meta: {
        pageTitle: VueI18n.t('page.video-stream.title'),
        resource: 'profile',
        contentClass: 'video-stream-page',
      },
    },
    {
      path: '/exercises',
      component: () => import('@/views/Courses.vue'),
      meta: {
        pageTitle: VueI18n.t('page.exercises.title'),
        resource: 'exercises',
      },
      children: [
        {
          path: '',
          name: 'cabinet.exercises',
          component: () => import('@/views/exercises/Exercises.vue'),
          meta: {
            pageTitle: VueI18n.t('page.exercises.title'),
            resource: 'exercises',
          },
        },
        {
          path: 'add',
          name: 'cabinet.exercises.add',
          component: () => import('@/views/exercises/Exercise.vue'),
          meta: {
            pageTitle: VueI18n.t('page.exercises.add'),
            resource: 'exercises',
          },
        },
        {
          path: ':exercise_id',
          name: 'cabinet.exercise',
          component: () => import('@/views/exercises/Exercise.vue'),
          meta: {
            pageTitle: VueI18n.t('page.exercises.add'),
            resource: 'exercises',
          },
        },
      ],
    },
    {
      path: '/courses',
      component: () => import('@/views/Courses.vue'),
      meta: {
        pageTitle: VueI18n.t('page.courses.title'),
        resource: 'courses',
      },
      children: [
        {
          path: '',
          name: 'cabinet.courses',
          component: () => import('@/views/courses/Courses.vue'),
          meta: {
            pageTitle: VueI18n.t('page.courses.title'),
            resource: 'courses',
          },
        },
        {
          path: ':course_id',
          name: 'cabinet.course',
          component: () => import('@/views/courses/Course.vue'),
          meta: {
            pageTitle: VueI18n.t('page.course.title'),
            resource: 'courses',
          },
        },
        {
          path: 'steps/add',
          name: 'cabinet.steps.add',
          component: () => import('@/views/courses/steps/Step.vue'),
          meta: {
            pageTitle: VueI18n.t('page.steps.add'),
            resource: 'courses',
          },
        },
        {
          path: 'steps/:step_id',
          name: 'cabinet.step',
          component: () => import('@/views/courses/steps/Step.vue'),
          meta: {
            pageTitle: VueI18n.t('page.steps.edit'),
            resource: 'step',
          },
        },
      ],
    },
    {
      path: '/hometasks',
      component: () => import('@/views/Courses.vue'),
      meta: {
        pageTitle: VueI18n.t('page.hometasks.title'),
        resource: 'hometasks',
      },
      children: [
        {
          path: '',
          name: 'cabinet.hometasks',
          component: () => import('@/views/hometasks/Hometasks.vue'),
          meta: {
            pageTitle: VueI18n.t('page.hometasks.title'),
            resource: 'hometasks',
          },
        },
        {
          path: 'add',
          name: 'cabinet.hometasks.add',
          component: () => import('@/views/hometasks/Hometask.vue'),
          meta: {
            pageTitle: 'Создание домашнего занятия',
            resource: 'hometasks',
          },
        },
        {
          path: ':task_id',
          name: 'cabinet.hometasks.task',
          component: () => import('../views/hometasks/Hometask.vue'),
          meta: {
            pageTitle: VueI18n.t('page.hometasks.task.title'),
            resource: 'hometasks',
          },
        },
      ],
    },
    {
      path: '/teachers',
      name: 'cabinet.teachers',
      component: () => import('@/views/people/Teachers.vue'),
      meta: {
        pageTitle: VueI18n.t('page.teachers.title'),
        resource: 'teachers',
      },
    },
    {
      path: '/students',
      name: 'cabinet.students',
      component: () => import('@/views/people/Students.vue'),
      meta: {
        pageTitle: VueI18n.t('page.students.title'),
        resource: 'students',
      },
    },
    {
      path: '/study-programs',
      name: 'cabinet.study_programs',
      component: () => import('@/views/study-programs/StudyPrograms.vue'),
      meta: {
        pageTitle: 'Учебные программы',
        resource: 'students',
      },
    },
    {
      path: '/profile',
      name: 'cabinet.profile',
      component: () => import('@/views/Profile.vue'),
      meta: {
        pageTitle: VueI18n.t('page.profile.title'),
        resource: 'profile',
      },
    },
    {
      path: '/subscriptions',
      name: 'cabinet.subscriptions',
      component: () => import('@/views/subscriptions/SubscriptionsAndPlans.vue'),
      meta: {
        pageTitle: VueI18n.t('page.subscriptions.title'),
        resource: 'subscriptions',
      },
    },
    {
      path: '/chats',
      name: 'cabinet.chat',
      component: () => import('@/views/chat/Chat.vue'),
      meta: {
        pageTitle: VueI18n.t('page.chats.title'),
        resource: 'chats',
      },
    },
    {
      path: '/leads',
      component: () => import('@/views/Courses.vue'),
      meta: {
        pageTitle: 'Заявки',
        resource: 'leads',
      },
      children: [
        {
          path: '',
          name: 'cabinet.leads',
          component: () => import('@/views/people/Leads.vue'),
          meta: {
            pageTitle: 'Заявки',
            resource: 'leads',
          },
        },
        {
          path: 'replies',
          name: 'cabinet.lead.replies',
          component: () => import('@/views/people/LeadReplies.vue'),
          meta: {
            pageTitle: 'Подбор репетитора',
            resource: 'lead-replies',
          },
        },
        {
          path: ':lead_id',
          name: 'cabinet.lead',
          component: () => import('@/views/people/LeadPage.vue'),
          meta: {
            pageTitle: 'Заявка',
            resource: 'leads',
          },
        },
      ],
    },
    {
      path: '/interactive/courses',
      name: 'cabinet.interactive.courses',
      component: () => import('@/views/interactive/InteractiveCourses.vue'),
      meta: {
        pageTitle: 'Курсы',
        resource: 'interactive',
      },
    },
    {
      path: '/admin/comments',
      name: 'cabinet.admin.step_comments',
      component: () => import('@/views/admin/steps/Comments.vue'),
      meta: {
        pageTitle: 'Комментарии',
        resource: 'comments',
      },
    },
    {
      path: '/admin/chats',
      name: 'cabinet.admin.chat',
      component: () => import('@/views/admin/chat/Chat.vue'),
      meta: {
        pageTitle: VueI18n.t('page.chats.title'),
        resource: 'admin-chats',
      },
    },
    {
      path: '/settings',
      name: 'cabinet.settings',
      component: () => import('@/views/Settings.vue'),
      meta: {
        pageTitle: VueI18n.t('page.settings.title'),
        resource: 'settings',
      },
    },
    {
      path: '/admin/teachers',
      name: 'cabinet.admin.teachers',
      component: () => import('@/views/admin/users/teachers/Teachers.vue'),
      meta: {
        pageTitle: VueI18n.t('page.admin_teachers.title'),
        resource: 'admin-teachers',
      },
    },
    {
      path: '/admin/students',
      name: 'cabinet.admin.students',
      component: () => import('@/views/admin/users/students/Students.vue'),
      meta: {
        pageTitle: VueI18n.t('page.admin_students.title'),
        resource: 'admin-students',
      },
    },
    {
      path: '/admin/subscriptions',
      name: 'cabinet.admin.subscriptions',
      component: () => import('@/views/admin/payments/SubscriptionPayments.vue'),
      meta: {
        pageTitle: VueI18n.t('page.admin_subscriptions.title'),
        resource: 'admin-subscriptions',
      },
    },
    {
      path: '/admin/leads',
      component: () => import('@/views/Courses.vue'),
      meta: {
        pageTitle: 'Заявки',
        resource: 'admin-leads',
      },
      children: [
        {
          path: '',
          name: 'cabinet.admin.leads',
          component: () => import('@/views/admin/leads/AdminLeads.vue'),
          meta: {
            pageTitle: 'Заявки',
            resource: 'admin-leads',
          },
        },
        {
          path: ':lead_id',
          name: 'cabinet.admin.lead',
          component: () => import('@/views/admin/leads/AdminLead.vue'),
          meta: {
            pageTitle: 'Заявка',
            resource: 'admin-leads',
          },
        },
      ],
    },
    // Auth
    {
      path: '/login',
      name: 'auth.login',
      component: () => import('@/views/auth/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'auth',
      },
    },
    {
      path: '/register',
      name: 'auth.register',
      component: () => import('@/views/auth/Register.vue'),
      meta: {
        layout: 'full',
        resource: 'auth',
      },
    },
    {
      path: '/reset',
      name: 'auth.password_reset',
      component: () => import('@/views/auth/ResetPassword.vue'),
      meta: {
        layout: 'full',
        resource: 'auth',
      },
    },
    // misc
    {
      path: '/help',
      name: 'cabinet.help',
      component: () => import('@/views/Help.vue'),
      meta: {
        pageTitle: 'Помощь',
        resource: 'profile',
      },
    },
    {
      path: '/how-to',
      name: 'cabinet.instruction',
      component: () => import('@/views/Instruction.vue'),
      meta: {
        pageTitle: 'Как получать заявки',
        resource: 'profile',
      },
    },
    // Errors
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        resource: 'error',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
      meta: {
        resource: 'error',
      },
    },
  ],
})

const getOptionsIfCan = (from = null) => ({ name: from?.name ?? 'cabinet.dashboard', params: from?.params ?? {} })
const goTo = (options, next) => (options ? next(options) : next())

router.beforeEach((to, from, next) => {
  const isAuthed = !!useJwt.getToken()
  const isAuthRoute = to.name.split('.')[0] === 'auth'
  let options = null

  if (!isAuthed && !isAuthRoute) {
    next({ name: 'auth.login' })
  } else if (isAuthed && isAuthRoute) {
    next({ name: 'cabinet.dashboard' })
  } else if (!isAuthed && isAuthRoute) {
    next()
  } else if (isAuthed && !store.getters['user/get_user']) {
    axiosIns.get('/user/current?expand=usersStudent,usersTeachers,image').then(async user => {
      await store.dispatch('user/setUser', { user }, { root: true })
      if (!canNavigate(to)) options = getOptionsIfCan(from)
      goTo(options, next)
      // eslint-disable-next-line no-empty
    }).catch(() => {})
  } else {
    if (!canNavigate(to)) options = getOptionsIfCan(from)
    goTo(options, next)
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(async () => {
// Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    // if (appLoading.style.display !== 'none') {
    //   if (to.name.split('.')[0] !== 'auth' && to.name !== 'home' && useJwt.getToken() && !store.getters['user/get_user']) {
    //     axiosIns.get('/user/current').then(async user => {
    //       await store.dispatch('user/setUser', { user }, { root: true })
    //     })
    //   }
    // }
    appLoading.style.display = 'none'
  }
})

export default router
