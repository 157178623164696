import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
// eslint-disable-next-line import/no-cycle
import user from './user'
import course from './course'
import videoStream from './video-stream'
import subscription from './subscription'
import chat from './chat'
import exercise from './exercise'
import shared from './shared'
import websockets from './websockets'
import sessions from './sessions/index'
import hometask from './hometask/index'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    user,
    course,
    videoStream,
    subscription,
    chat,
    exercise,
    shared,
    websockets,
    sessions,
    hometask,
  },
  strict: process.env.DEV,
})
