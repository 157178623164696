<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <div
      v-if="show"
      class="user-media-request-hint"
    >
      <b-img
        v-if="!noMic"
        class="user-media-request-hint__arrow"
        :class="{'not-allowed': notAllowed}"
        :src="require('@/assets/images/misc/user-media-arrow.png')"
        alt
      />
      <div
        class="d-flex flex-column align-items-center justify-content-center text-center h-100"
      >
        <div class="mt-2">
          <div v-if="!noMic">
            <feather-icon
              size="75"
              class="mb-2"
              :class="notAllowed ? 'text-danger' : 'text-warning'"
              :icon="notAllowed ? 'SlashIcon' : 'SlidersIcon'"
            />
            <h3 class="text-white font-large-1 mb-1">
              Разрешите доступ к камере и микрофону
            </h3>
          </div>
          <div v-else>
            <feather-icon
              size="75"
              class="mb-2 text-danger"
              icon="MicOffIcon"
            />
            <h3 class="text-white font-large-1 mb-1">
              На вашем компьютере не обнаружен микрофон
            </h3>
          </div>
          <div v-if="notAllowed">
            <div v-if="!noMic">
              <p class="font-medium-2 text-white-50 mb-0">
                Похоже, вы запретили доступ к камере и микрофону
              </p>
              <p class="font-medium-2 text-white-50 mb-0">
                Нажмите на иконку замочка и разрешите веб-сайту получить доступ к камере и микрофону
              </p>
            </div>
            <div v-else>
              <p class="font-medium-2 text-white-50 mb-0">
                К сожалению, без микрофона невозможно начать звонок. Пожалуйста, подключите микрофон и повторите попытку
              </p>
            </div>
          </div>
          <div v-else>
            <p class="font-medium-2 text-white-50 mb-0">
              Для корректной работы видеозвонка, сайту необходим доступ к вашим камере и микрофону
            </p>
            <p class="font-medium-2 text-white-50 mb-0">
              Нажмите "Разрешить" в появившемся окне слева сверху
            </p>
          </div>
          <b-img
            v-if="!noMic"
            class="mt-2"
            style="border-radius: 6px"
            :src="require(`@/assets/images/misc/${notAllowed ? 'user-media-switches.png' : 'user-media-switches-request.png'}`)"
            alt
          />
        </div>
        <b-button
          class="mt-2"
          variant="outline-secondary"
          @click="closeHint"
        >
          Закрыть
        </b-button>
      </div>
    </div>
  </transition>
</template>

<script>
import {
  BImg, BButton,
} from 'bootstrap-vue'

export default {
  name: 'UserMediaRequestHint',
  components: {
    BImg,
    BButton,
  },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    notAllowed: {
      type: Boolean,
      required: true,
    },
    closeHint: {
      type: Function,
      required: true,
    },
    noMic: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>
