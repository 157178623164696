import Vue from 'vue'
import { format, isToday } from 'date-fns'
import { ru } from 'date-fns/locale'
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import VueI18n from '@/libs/i18n'

const timeZone = 'Europe/Moscow'

Vue.filter('dateTime', (value, noTodayHint = false) => {
  if (!value) return ''
  let date = zonedTimeToUtc(value, timeZone)
  date = utcToZonedTime(date)
  const today = isToday(date)

  let todayHint = ''
  if (!noTodayHint) todayHint = `${VueI18n.t('dates.today')} в `
  if (today) return `${todayHint}${format(date, 'HH:mm')}`
  return format(date, 'dd.MM.yyyy HH:mm')
})
Vue.filter('onlyDate', value => {
  if (!value) return ''
  const date = zonedTimeToUtc(value, timeZone)
  return format(utcToZonedTime(date), 'dd.MM.yyyy')
})
Vue.filter('dateWithMonthName', value => {
  if (!value) return ''
  const date = zonedTimeToUtc(value, timeZone)
  return format(utcToZonedTime(date), 'D MMMM YYYY', { locale: ru })
})
Vue.filter('onlyTime', value => {
  if (!value) return ''
  const date = zonedTimeToUtc(value, timeZone)
  return format(utcToZonedTime(date), 'HH:mm')
})
Vue.filter('price', (value, toFixed = null) => {
  let val = value
  if (val === null || val === undefined) val = 0
  return parseFloat(val).toFixed(toFixed !== null ? toFixed : 2)
})
Vue.filter('timeIfToday', value => {
  if (!value) return ''
  let date = zonedTimeToUtc(value, timeZone)
  date = utcToZonedTime(date)
  const today = isToday(date)

  if (today) return `${format(date, 'HH:mm')}`
  return format(date, 'eee HH:mm', { locale: ru })
})
