import axiosIns from '@/libs/axios'
import { SESSION_STATUS_PASSED } from '@/shared/constants'

export default {
  namespaced: true,
  state: {
    steps: [],
    exercises: [],
    session: null,
    tab: null,
    current_lesson_tab: null,
    current_session_lessons: [],
  },
  getters: {
    get_steps: state => state.steps,
    get_exercises: state => state.exercises,
    get_session: state => state.session,
    get_selected_tab: state => state.tab,
    get_current_lesson_tab: state => state.current_lesson_tab,
    get_current_session_lessons: state => state.current_session_lessons,
  },
  mutations: {
    SET_STEPS: (state, steps) => {
      state.steps = steps
    },
    SET_EXERCISES: (state, exercises) => {
      state.exercises = exercises
    },
    RESET_STATE: state => {
      state.exercises = []
      state.steps = []
    },
    SET_SESSION: (state, session) => {
      state.session = session
    },
    SET_TAB: (state, tab) => {
      state.tab = tab
    },
    SET_CURRENT_LESSON_TAB: (state, tab) => {
      state.current_lesson_tab = tab
    },
    UPDATE_CURRENT_SESSION_LESSONS_HISTORY: (state, lessons) => {
      state.current_session_lessons = lessons
    },
  },
  actions: {
    setSelectedTab({ commit }, tab) {
      commit('SET_TAB', tab)
    },
    setCurrentLessonTab({ commit }, tab) {
      commit('SET_CURRENT_LESSON_TAB', tab)
    },
    setLessonSteps({ commit }, steps) {
      commit('SET_STEPS', steps)
    },
    setLessonExercises({ commit }, exercises) {
      commit('SET_EXERCISES', exercises)
    },
    async getSession({ commit }, sessionId) {
      const data = await axiosIns.get(`/sessions/view?id=${sessionId}&expand=sessionStudents,teacherRooms`)
      commit('SET_SESSION', data)
      return data
    },
    async clearSession({ commit }) {
      commit('SET_SESSION', null)
    },
    async updateSession({ commit }, { sessionId, data }) {
      const session = await axiosIns.put(`/sessions/update?id=${sessionId}&expand=sessionStudents,teacherRooms`, { ...data })
      commit('SET_SESSION', session)
    },
    async startSession({ commit }, { sessionId, roomId }) {
      const session = await axiosIns.post('/sessions/start', {
        session_id: sessionId,
        room_id: roomId,
      }, {
        params: {
          expand: 'sessionStudents,teacherRooms',
        },
      })
      commit('SET_SESSION', session)
    },
    // eslint-disable-next-line no-unused-vars
    async endSession({ commit }, { sessionId }) {
      const session = await axiosIns.post('/sessions/finish', { session_id: sessionId })
      return session
    },
    // eslint-disable-next-line no-unused-vars
    async joinSession({ commit }, { sessionId, student_id }) {
      await axiosIns.post('/session-students/create', { session_id: sessionId, student_id })
    },
    async saveSession({ commit }, { sessionId, name, comment }) {
      await axiosIns.put(`/sessions/update?id=${sessionId}&expand=sessionStudents,teacherRooms`, { comments: comment, name, status: SESSION_STATUS_PASSED })
      // dispatch('addHometaskToSession', session)
      commit('SET_SESSION', null)
    },
    // eslint-disable-next-line no-unused-vars
    async addHometaskToSession({ commit }, session) {
      const { sessionStudents } = session
      const tasks = []
      sessionStudents.forEach(async student => {
        const newTask = await axiosIns.post('/home-tasks/create?', {
          lesson_session_id: session.id,
          student_id: student.student_id,
          teacher_id: session.teacher_id,
          name: session.name,
          status: 0,
        })
        tasks.push(newTask)
      })
      return tasks
    },
    async deleteSession({ commit }, { sessionId }) {
      await axiosIns.delete(`/sessions/delete?id=${sessionId}`)
      commit('SET_SESSION', null)
    },
    // eslint-disable-next-line no-unused-vars
    async addFileToSessionStore({ commit }, { sessionId, type, file }) {
      const formData = new FormData()
      // formData.append('type', type)
      formData.append('src', file)

      const store = await axiosIns.post('/store/create', formData)
      await axiosIns.post('/lesson-sessions-store/create', {
        session_id: sessionId,
        store_id: store.id,
      })
    },
    resetState({ commit }) {
      commit('RESET_STATE')
    },
    // eslint-disable-next-line no-unused-vars
    async saveBoard({ commit }, board) {
      let boards = localStorage.getItem('saved_boards')
      if (boards) boards = JSON.parse(boards)
      else boards = []
      boards.push(board)
      localStorage.setItem('saved_boards', JSON.stringify(boards))
    },
    // eslint-disable-next-line no-unused-vars
    async removeSavedBoard({ commit }, id) {
      let boards = JSON.parse(localStorage.getItem('saved_boards'))
      boards = boards.filter(board => board.id !== id)
      localStorage.setItem('saved_boards', JSON.stringify(boards))
    },
    async saveLessonToHistory({ commit, state }, lessonId) {
      if (state.session && lessonId) {
        let lessonsHistory = state.current_session_lessons
        if (!lessonsHistory.find(lesson => lesson.id === lessonId)) {
          // eslint-disable-next-line no-unused-vars
          const response = await axiosIns.post('/lesson-session-lessons-history/create?expand=lesson', { lesson_id: lessonId, session_id: state.session.id }).catch(err => {})
          if (response) {
            lessonsHistory = [...lessonsHistory, response.lesson]
            commit('UPDATE_CURRENT_SESSION_LESSONS_HISTORY', lessonsHistory)
          }
        }
      }
    },
  },
}
