import * as _ from 'lodash'
import axiosIns from '@/libs/axios'

const courseReadyType = 0
const courseUserType = 1
const courseFavoritesType = 2
const courseAssignedType = 3

export default {
  namespaced: true,
  state: {
    mode: false,
    course: null,
    themes: [],
    themes_meta: null,
    selected_theme: null,
    lessons: [],
    lessons_meta: null,
    selected_lesson: null,
    lesson_steps: [],
    lesson_steps_meta: null,
    selected_lesson_step: null,
    types: [
      {
        name: 'ready-courses',
        title: 'Каталог',
        icon: 'BriefcaseIcon',
        type: courseReadyType,
      },
      {
        name: 'favorites',
        title: 'Избранное',
        icon: 'StartIcon',
        type: courseFavoritesType,
      },
    ],
    course_ready_type: courseReadyType,
    course_user_type: courseUserType,
    course_favorites_type: courseFavoritesType,
    course_assigned_type: courseAssignedType,
    subjects: [],
    grades: [],
  },
  getters: {
    get_mode_editing_active: state => state.mode,
    get_course: state => state.course,
    get_themes: state => state.themes,
    get_themes_meta: state => state.themes_meta,
    get_selected_theme: state => state.selected_theme,
    get_lessons: state => state.lessons,
    get_lessons_meta: state => state.lessons_meta,
    get_selected_lesson: state => state.selected_lesson,
    get_lesson_steps: state => state.lesson_steps,
    get_lesson_steps_meta: state => state.lesson_steps_meta,
    get_selected_lesson_step: state => state.selected_lesson_step,
    get_courses_types: state => state.types,
    get_course_user_type: state => state.course_user_type,
    get_course_ready_type: state => state.course_ready_type,
    get_course_favorites_type: state => state.course_favorites_type,
    get_course_assigned_type: state => state.course_assigned_type,
    get_subjects: state => state.subjects,
    get_grades: state => state.grades,
  },
  mutations: {
    SET_SUBJECTS: (state, subjects) => {
      state.subjects = subjects
    },
    SET_GRADES: (state, grades) => {
      state.grades = grades
    },
    SET_MODE: (state, mode) => {
      state.mode = mode
    },
    SET_COURSE: (state, course) => {
      state.course = course
    },
    SET_THEMES: (state, themes) => {
      state.themes = themes
    },
    SET_THEMES_META: (state, meta) => {
      state.themes_meta = meta
    },
    SET_THEME: (state, themeId) => {
      state.selected_theme = themeId
      if (!themeId) {
        state.lessons = []
        state.lessons_meta = null
        state.selected_lesson = null
        state.lesson_steps = []
        state.lesson_steps_meta = null
        state.selected_lesson_step = null
      }
    },
    SET_LESSONS: (state, lessons) => {
      state.lessons = lessons
      state.selected_lesson = null
      state.lesson_steps = []
      state.lesson_steps_meta = null
      state.selected_lesson_step = null
    },
    SET_LESSONS_META: (state, meta) => {
      state.lessons_meta = meta
    },
    SET_LESSON: (state, lessonId) => {
      state.selected_lesson = lessonId
      state.lesson_steps = []
      state.selected_lesson_step = null
    },
    SET_LESSON_STEPS: (state, steps) => {
      state.lesson_steps = steps
    },
    SET_LESSON_STEPS_META: (state, steps) => {
      state.lesson_steps_meta = steps
    },
    SET_LESSON_STEP: (state, step) => {
      state.selected_lesson_step = step
    },
    ADD_LESSON_STEP: (state, step) => {
      state.lesson_steps.push(step)
    },
    REMOVE_LESSON_STEP: (state, step) => {
      const steps = [...state.lesson_steps.filter(s => s.id !== step)]
      if (state.selected_lesson_step === step) {
        state.selected_lesson_step = steps.length > 0 ? steps[0].id : null
      }
      state.lesson_steps = steps
    },
  },
  actions: {
    async dropState({ commit }) {
      commit('SET_MODE', false)
      commit('SET_COURSE', null)
      commit('SET_THEMES', [])
      commit('SET_THEMES_META', null)
      commit('SET_THEME', null)
      commit('SET_LESSONS', [])
      commit('SET_LESSONS_META', null)
      commit('SET_LESSON', null)
      commit('SET_LESSON_STEPS', [])
      commit('SET_LESSON_STEPS_META', null)
      commit('SET_LESSON_STEP', null)
    },
    async getUsersCourse({ commit, dispatch }, courseId) {
      await dispatch('dropState')
      const course = await axiosIns.get(`/users-courses/view?id=${courseId}`)
      commit('SET_COURSE', course)
    },
    async getCourse({ commit, dispatch }, courseId) {
      await dispatch('dropState')
      const course = await axiosIns.get(`/courses/view?id=${courseId}`)
      commit('SET_COURSE', course)
    },
    async getUsersThemes({ commit, state }, { page, limit }) {
      const { items, _meta } = await axiosIns.get(`/users-themes/index?course_id=${state.course.id}&page=${page}&limit=${limit}`)
      commit('SET_THEMES_META', _meta)
      commit('SET_THEMES', items)
    },
    async getThemes({ commit, state }, { page, limit }) {
      const { items, _meta } = await axiosIns.get(`/themes/index?course_id=${state.course.id}&page=${page}&limit=${limit}`)
      commit('SET_THEMES_META', _meta)
      commit('SET_THEMES', items)
    },
    setTheme({ commit }, themeId) {
      commit('SET_THEME', themeId)
    },
    async getUsersLessons({ commit, state }, { page, limit }) {
      const { items, _meta } = await axiosIns.get(`/users-lessons/index?theme_id=${state.selected_theme}&page=${page}&limit=${limit}`)
      commit('SET_LESSONS_META', _meta)
      commit('SET_LESSONS', items)
    },
    async getLessons({ commit, state }, { page, limit }) {
      const { items, _meta } = await axiosIns.get(`/lessons/index?theme_id=${state.selected_theme}&page=${page}&limit=${limit}`)
      commit('SET_LESSONS_META', _meta)
      commit('SET_LESSONS', items)
    },
    setLesson({ commit }, lessonId) {
      commit('SET_LESSON', lessonId)
    },
    async getUsersLessonsSteps({ commit, state }, { page, limit }) {
      const { items, _meta } = await axiosIns.get(`/users-lesson-steps/index?lesson_id=${state.selected_lesson}&expand=usersStepElements&page=${page}&limit=${limit}`)
      commit('SET_LESSON_STEPS_META', _meta)
      commit('SET_LESSON_STEPS', _.sortBy(items, 'number'))
    },
    async getLessonsSteps({ commit, state }, { page, limit }) {
      const { items, _meta } = await axiosIns.get(`/lesson-steps/index?lesson_id=${state.selected_lesson}&page=${page}&limit=${limit}`)
      commit('SET_LESSON_STEPS_META', _meta)
      commit('SET_LESSON_STEPS', _.sortBy(items, 'number'))
    },
    addLessonStep({ commit }, step) {
      commit('ADD_LESSON_STEP', step)
    },
    removeLessonStep({ commit }, step) {
      commit('REMOVE_LESSON_STEP', step)
    },
    setLessonStep({ commit, state }, stepIndex) {
      commit('SET_LESSON_STEP', stepIndex !== null ? state.lesson_steps[stepIndex].id : null)
    },
    async getLessonStepElements({ state }, users = false) {
      const urlPrefix = users ? 'users-step-elements' : 'step-elements'
      const data = await axiosIns.get(`/${urlPrefix}/index?step_id=${state.selected_lesson_step}`, {
        params: {
          expand: 'image',
        },
      })
      return data
    },
    setModeEditingActive({ commit }, mode) {
      commit('SET_MODE', mode)
    },
    async getSubjects({ commit }) {
      const items = await axiosIns.get('/subjects')
      commit('SET_SUBJECTS', items)
    },
    async getGrades({ commit }) {
      const { items } = await axiosIns.get('/grades')
      commit('SET_GRADES', items)
    },
    // eslint-disable-next-line no-empty-pattern
    async addStepToFavorites({ }, stepId) {
      const favorite = await axiosIns.post('/favorite-steps/create', { lesson_step_id: stepId })
      return favorite
    },
    // eslint-disable-next-line no-empty-pattern
    async removeFromFavorites({ }, stepId) {
      await axiosIns.delete('/favorite-steps/current-user-delete', {
        params: {
          lesson_step_id: stepId,
        },
      })
    },
    // eslint-disable-next-line no-empty-pattern
    async copyStep({ }, stepId) {
      const copy = await axiosIns.post('/lesson-steps/copy', { id: stepId })
      return copy
    },
  },
}
