import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    tags: [],
  },
  getters: {
    get_tags: state => state.tags,
  },
  mutations: {
    SET_TAGS: (state, types) => {
      state.types = types
    },
  },
  actions: {
    async getAllTags({ commit }) {
      const { items } = await axiosIns.get('/tags/index')
      commit('SET_TAGS', items)
    },
  },
}
