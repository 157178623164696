<template>
  <div class="toastification">
    <div class="d-flex align-items-start">
      <b-avatar
        variant="danger"
        size="1.8rem"
        class="mr-75 flex-shrink-0"
      >
        <AlertTriangleIcon size="15" />
      </b-avatar>
      <div class="d-flex flex-grow-1">
        <div>
          <h5
            v-if="title"
            class="mb-0 font-weight-bolder toastification-title text-danger"
            v-text="title"
          />
          <template v-if="typeof text === 'string'">
            <small
              class="d-inline-block text-body"
              v-text="text"
            />
          </template>
          <template v-if="Array.isArray(text)">
            <small
              v-for="(t, i) in text"
              :key="i"
              class="d-block text-body"
              v-text="t"
            />
          </template>
        </div>
        <span
          class="cursor-pointer toastification-close-icon ml-auto "
          @click="$emit('close-toast')"
        >
          <feather-icon
            v-if="!hideClose"
            icon="XIcon"
            class="text-body"
          />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import { AlertTriangleIcon } from 'vue-feather-icons'

export default {
  components: {
    BAvatar,
    AlertTriangleIcon,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    text: {
      type: [String, Array],
      default: null,
    },
    hideClose: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.toastification-close-icon,
.toastification-title {
  line-height: 26px;
}

.toastification-title {
  color: inherit;
}
</style>
