import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchChatsAndContacts() {
      const items = [
        {
          fullName: 'Petr',
          email: 'developer@mail.com',
          chat: {
            lastMessage: null,
          },
        },
        {
          fullName: 'Denis',
          email: 'developer@mail.com',
          chat: {
            lastMessage: null,
          },
        },
      ]
      return { items }
      // return new Promise((resolve, reject) => {
      //   axios
      //     .get('/user/index')
      //     .then(response => resolve(response))
      //     .catch(error => reject(error))
      // })
    },
    getProfileUser() {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/current')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getChat(ctx, { userId }) {
      return {
        contact: {
          user_id: userId,
          fullName: 'Denis',
          email: 'developer@mail.com',
        },
      }
      // return new Promise((resolve, reject) => {
      //   axios
      //     .get(`/apps/chat/chats/${userId}`)
      //     .then(response => resolve(response))
      //     .catch(error => reject(error))
      // })
    },
    sendMessage(ctx, { contactId, message, senderId }) {
      return {
        newMessageData: {
          senderId,
          contactId,
          message,
          time: Date.now(),
        },
        chat: {
          contact: {
            user_id: senderId,
            fullName: 'Denis',
            email: 'developer@mail.com',
          },
          chat: [
            {
              senderId,
              contactId,
              message,
              time: Date.now(),
            },
          ],
        },
      }
      // return new Promise((resolve, reject) => {
      //   axios
      //     .post(`/apps/chat/chats/${contactId}`, { message, senderId })
      //     .then(response => resolve(response))
      //     .catch(error => reject(error))
      // })
    },
  },
}
