import { $themeBreakpoints } from '@themeConfig'
import axiosIns from '@axios'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    loader: false,
    settings: [],
  },
  getters: {
    settings: state => state.settings,
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    loader: state => state.loader,
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    TOGGLE_LOADER(state, loader) {
      state.loader = loader
    },
    SET_SETTINGS(state, settings) {
      state.settings = settings
    },
  },
  actions: {
    async getSettings({ commit }) {
      const { items } = await axiosIns.get('/settings')
      const settings = {}
      items.forEach(item => {
        settings[item.key] = item.value
      })
      commit('SET_SETTINGS', settings)
    },
  },
}
