import axiosIns, { axiosChatsIns } from '@/libs/axios'

export default {
  namespaced: true,
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async deleteSession({ commit }, sessionId) {
      axiosChatsIns.get(`/sessions/${sessionId}/deleted`)
      return new Promise((resolve, reject) => {
        axiosIns.delete(`/sessions/delete?id=${sessionId}`).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
