import Vue from 'vue'
import axios from 'axios'
import ErrorToast from '@core/components/toastification/ErrorToast.vue'
import * as _ from 'lodash'
import store from '../store'
import VueI18n from '@/libs/i18n'
import useJwt from '@/auth/jwt/useJwt'
import router from '@/router'

// axios

const axiosLazyIns = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_PREFIX}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

const axiosIns = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_API_PREFIX}`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

const axiosChatsIns = axios.create({
  baseURL: process.env.VUE_APP_CHAT_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
})

// Request interceptor
axiosIns.interceptors.request.use(async request => {
  if (!request.data) {
    // eslint-disable-next-line no-param-reassign
    request.data = {}
  }
  return request
})

axiosLazyIns.interceptors.response.use(async response => {
  const { data } = response
  if (data.success) {
    return data.data
  }
  return Promise.reject(data.data)
}, async error => Promise.reject(error.response))

axiosChatsIns.interceptors.request.use(async request => {
  if (!request.data) {
    // eslint-disable-next-line no-param-reassign
    request.data = {}
  }
  return request
})

axiosChatsIns.interceptors.response.use(async response => {
  const { data } = response
  return data
}, async error => {
  if (!error.response) return Promise.reject(error)

  const { status, data, config: originalRequest } = error.response

  if (status === 401) {
    if (!useJwt.isAlreadyFetchingAccessToken) {
      useJwt.isAlreadyFetchingAccessToken = true
      useJwt.refreshToken().then(refresh => {
        useJwt.isAlreadyFetchingAccessToken = false

        // Update accessToken in localStorage
        useJwt.setToken(refresh.accessToken)
        useJwt.setRefreshToken(refresh.refreshToken)

        useJwt.onAccessTokenFetched(refresh.accessToken)
      })
    }
    if (!originalRequest.url.includes('auth')) {
      return new Promise(resolve => {
        useJwt.addSubscriber(accessToken => {
          // Make sure to assign accessToken according to your response.
          // Check: https://pixinvent.ticksy.com/ticket/2413870
          // Change Authorization header
          originalRequest.headers.Authorization = `${useJwt.jwtConfig.tokenType} ${accessToken}`
          resolve(axiosIns(originalRequest))
        })
      })
    }
  } else if (status === 422) {
    Vue.prototype.$toast({
      component: ErrorToast,
      props: {
        title: VueI18n.t('error!'),
        text: _.map(data.data, 'message'),
      },
    })
    const errObj = {}
    data.data.forEach(e => {
      errObj[e.field] = e.message
    })
    return Promise.reject(errObj)
  } else if (status === 404) {
    Vue.prototype.$toast({
      component: ErrorToast,
      props: {
        title: VueI18n.t('error!'),
      },
    })
    return Promise.reject(error.response)
  }

  return Promise.reject(error.response)
})

axiosIns.interceptors.response.use(async response => {
  store.commit('app/TOGGLE_LOADER', false)
  const { data } = response
  if (data.success) return data.data
  return Promise.reject(data.data)
}, async error => {
  if (!error.response) return Promise.reject(error)

  store.commit('app/TOGGLE_LOADER', false)
  const originalRequest = error.config

  if (error.response.status === 401) {
    if (!useJwt.isAlreadyFetchingAccessToken) {
      useJwt.isAlreadyFetchingAccessToken = true
      useJwt.refreshToken().then(refresh => {
        useJwt.isAlreadyFetchingAccessToken = false

        // Update accessToken in localStorage
        useJwt.setToken(refresh.accessToken)
        useJwt.setRefreshToken(refresh.refreshToken)

        useJwt.onAccessTokenFetched(refresh.accessToken)
      }).catch(() => {
        store.dispatch('user/removeUser', null, { root: true })
        router.replace({ name: 'auth.login' })
      })
    }
    if (!originalRequest.url.includes('auth')) {
      return new Promise(resolve => {
        useJwt.addSubscriber(accessToken => {
          // Make sure to assign accessToken according to your response.
          // Check: https://pixinvent.ticksy.com/ticket/2413870
          // Change Authorization header
          originalRequest.headers.Authorization = `${useJwt.jwtConfig.tokenType} ${accessToken}`
          resolve(axiosIns(originalRequest))
        })
      })
    }
  } else {
    const { status, data } = error.response
    if (status === 422) {
      Vue.prototype.$toast({
        component: ErrorToast,
        props: {
          title: VueI18n.t('error!'),
          text: _.map(data.data, 'message'),
        },
      })
      const errObj = {}
      data.data.forEach(e => {
        errObj[e.field] = e.message
      })
      return Promise.reject(errObj)
    }

    if (status === 404) {
      Vue.prototype.$toast({
        component: ErrorToast,
        props: {
          title: VueI18n.t('error!'),
        },
      })
      return Promise.reject(error.response)
    }
  }

  return Promise.reject(error.response)
})

axiosIns.storeCreate = async file => {
  const formData = new FormData()
  formData.append('src', file)
  const data = await axiosIns.post('/store/create', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
  return data
}

axiosIns.storeDelete = async id => {
  await axiosIns.delete(`/store/delete?id=${id}`)
}

axiosLazyIns.storeGet = async id => {
  const data = await axiosLazyIns.get(`/store/view?id=${id}`)
  return data
}

Vue.prototype.$http = axiosIns
Vue.prototype.$chatsHttp = axiosChatsIns
Vue.prototype.$lazyHttp = axiosLazyIns

export { axiosLazyIns, axiosChatsIns }
export default axiosIns
