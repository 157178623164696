import Vue from 'vue'
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload)

// or with options
const loadingImage = require('@/assets/images/loading.gif')
// const errorimage = require('./assets/error.gif')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: loadingImage,
  loading: loadingImage,
  attempt: 2,
})
