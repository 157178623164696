import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import VueScrollTo from 'vue-scrollto'
import VeeValidate, { Validator } from 'vee-validate'
import ru from 'vee-validate/dist/locale/ru'
import VueYoutube from 'vue-youtube'
import vueDebounce from 'vue-debounce'
import router from './router'
import store from './store'
import App from './App.vue'
import i18n from '@/libs/i18n'

// Global filters
import './filters'

// Skeleton loader
import 'v-skeletor/dist/v-skeletor.css'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/acl'
import '@/libs/lazy-load'
import '@/libs/rtc'

Vue.use(vueDebounce)

// Validation
Vue.use(VeeValidate, { fieldsBagName: 'veeFields' })
Validator.localize('ru', ru)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// VueScrollTo
Vue.use(VueScrollTo, {
  easing: 'ease',
  offset: -100,
})

// Youtube iframe plugin
Vue.use(VueYoutube)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

Vue.prototype.$appName = process.env.VUE_APP_APP_NAME

Vue.prototype.$videoStreamEventBus = new Vue()
Vue.prototype.$eventBus = new Vue()

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
