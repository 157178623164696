<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <div
      v-if="loader"
      class="preloader d-flex justify-content-center align-items-center"
    >
      <b-spinner
        variant="primary"
        label="Spinning"
        style="width: 4rem; height: 4rem;"
      />
    </div>
    <help-offer-modal v-if="shouldShowHelpModal && showHelpOffer" />
    <session-online-modal ref="sessionOnlineModal" />
    <device-setup
      v-if="isCabinet && showSettings"
      @onFinish="settingsClosed"
    />
    <interactive-modal
      v-if="isCabinet && isStudent && showInteractiveModal"
    />
  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { mapActions, mapGetters } from 'vuex'

import { useWindowSize, useCssVar } from '@vueuse/core'

import { BSpinner } from 'bootstrap-vue'
import store from '@/store'
import HelpOfferModal from './components/help/HelpOfferModal.vue'
import { HOMETASK_STATUS_ACTIVE, WEBSOCKETS_CHANNEL_SESSIONS } from './shared/constants'
import {
  adminRole, studentRole, teacherRole, userRoles,
} from './store/user'
import SessionOnlineModal from './components/session/SessionOnlineModal.vue'
import DeviceSetup from './components/settings/DeviceSetup.vue'
import InteractiveModal from './components/interactive/InteractiveModal.vue'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    InteractiveModal,
    DeviceSetup,
    SessionOnlineModal,
    HelpOfferModal,
    // Layouts
    LayoutVertical,
    LayoutFull,
    BSpinner,

  },
  data: () => ({
    showSettings: false,
    showHelpOffer: false,
    showInteractiveModal: false,
  }),
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapGetters({
      loader: 'app/loader',
      user: 'user/get_user',
      websockets: 'websockets/get_client',
    }),
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isStudent() {
      return this.userRole === studentRole
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isAdmin() {
      return this.userRole === adminRole
    },
    isCabinet() {
      return this.$route.name && this.$route.name.includes('cabinet')
    },
    shouldShowHelpModal() {
      return !this.isAdmin && !this.isStudent && this.isCabinet && this.$route.name !== 'cabinet.help'
    },
  },
  watch: {
    user() {
      if (this.isStudent) this.redirectToFirstHometask()
      else this.showSettings = true
      if (this.user) {
        setTimeout(async () => {
          await this.$store.dispatch('websockets/disconnect')
          await this.$store.dispatch('websockets/initConnection')
        }, 1000)
      }
    },
    websockets() {
      if (this.user) this.listenForStreams()
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  mounted() {
    this.getSettings()
  },
  methods: {
    ...mapActions({
      getSettings: 'app/getSettings',
    }),
    listenForStreams() {
      if (this.websockets && !this.onlineSessionsSubscription) {
        this.websockets.subscribe(`$user.${this.user.id}.${WEBSOCKETS_CHANNEL_SESSIONS}`, event => {
          const { data } = event
          switch (data.action) {
            case 'went-online':
              this.$refs.sessionOnlineModal.sessionOnline(data.sessionId)
              break
            case 'room-online':
              this.$refs.sessionOnlineModal.roomOnline(data.roomId)
              break
            default:
          }
        })
      }
    },
    async redirectToFirstHometask() {
      const { items } = await this.$http.get('/home-tasks', {
        params: {
          status: HOMETASK_STATUS_ACTIVE,
          sort: '-id',
          limit: 1,
          page: 1,
          student_id: this.user.id,
        },
      })
      const { id } = items[0] || {}
      if (id) {
        await this.$router.push({
          name: 'cabinet.hometasks.task',
          params: {
            task_id: id,
          },
        })
      } else {
        this.showSettings = true
      }
    },
    settingsClosed() {
      if (this.isStudent) {
        this.showInteractiveModal = true
      } else if (this.isTeacher) {
        this.showHelpOffer = true
      }
    },
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}
</script>

<style lang="scss">
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.15);
}
</style>
