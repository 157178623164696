import RTCMultiConnection from 'rtcmulticonnection'

const io = require('socket.io-client')

global.io = io

export const getUserMediaSettings = () => {
  const settings = localStorage.getItem('media-devices')
  if (settings) return JSON.parse(settings)
  return { mic: null, camera: null, speakers: null }
}

const userHasAnyCameras = async () => {
  let cameras = []
  await navigator.mediaDevices.enumerateDevices()
    .then(devices => {
      cameras = devices.filter(device => device.kind === 'videoinput')
    })
  return cameras.length > 0
}

async function getVideoConstraints() {
  const userHasCamera = await userHasAnyCameras()
  if (!userHasCamera) return false
  const constraints = {
    minWidth: 426,
    minHeight: 240,
    maxWidth: 1920,
    maxHeight: 1080,
  }
  const userSettings = getUserMediaSettings()
  if (userSettings.camera) constraints.deviceId = userSettings.camera
  return constraints
}

function getAudioConstraints() {
  const userSettings = getUserMediaSettings()
  const constraints = {
    echoCancellation: false,
    noiseSuppression: false,
    autoGainControl: false,
  }
  if (userSettings.mic) constraints.deviceId = userSettings.mic
  return constraints
}

export const setUserSelectedSpeakers = el => {
  const userSettings = getUserMediaSettings()
  el.setSinkId(userSettings.speakers)
}

export const RTCGetConstraints = async () => {
  const audio = getAudioConstraints()
  const video = await getVideoConstraints()

  return { audio, video }
}

export const createRTC = ({ audio, video }) => {
  const connection = new RTCMultiConnection()
  connection.enableLogs = false
  connection.autoCreateMediaElement = false
  connection.socketURL = process.env.VUE_APP_RTC_SOCKET_URL
  connection.mediaConstraints = {
    video,
    audio,
  }
  connection.sdpConstraints.mandatory = {
    OfferToReceiveAudio: true,
    OfferToReceiveVideo: true,
    VoiceActivityDetection: false,
    IceRestart: true,
  }
  const { CodecsHandler } = connection
  connection.processSdp = sdp => {
    let modifiedSdp = sdp
    const codec = 'vp9'

    modifiedSdp = CodecsHandler.preferCodec(modifiedSdp, codec.toLowerCase())

    // modifiedSdp = CodecsHandler.setApplicationSpecificBandwidth(modifiedSdp, {
    //   audio: 128,
    //   video: 1024,
    //   screen: 1024,
    // })

    modifiedSdp = CodecsHandler.setVideoBitrates(modifiedSdp, {
      min: 64 * 8 * 1024,
      max: 700 * 8 * 1024,
    })

    return modifiedSdp
  }
  connection.iceServers = []
  connection.iceServers.push({
    urls: [
      process.env.VUE_APP_RTC_STUN_URL,
      `${process.env.VUE_APP_RTC_STUN_URL}?transport=udp`,

      // 'stun:stun.l.google.com:19302',
      // 'stun:stun1.l.google.com:19302',
      // 'stun:stun2.l.google.com:19302',
      // 'stun:stun.l.google.com:19302?transport=udp',
    ],
  })
  connection.iceServers.push({
    urls: [
      process.env.VUE_APP_RTC_TURN_URL,
      `${process.env.VUE_APP_RTC_TURN_URL}?transport=udp`,
    ],
    username: process.env.VUE_APP_RTC_TURN_USERNAME,
    credential: process.env.VUE_APP_RTC_TURN_PASSWORD,
  })
  connection.candidates = {
    turn: true,
    stun: true,
    host: true,
  }
  // connection.iceTransportPolicy = process.env.VUE_APP_RTC_ICE_TRANSPORT_POLICY
  connection.iceTransportPolicy = 'all'
  connection.session = {
    audio: true,
    video: true,
    data: true,
    // screen: true,
    // oneway: true,
  }

  return connection
}
