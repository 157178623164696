import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {
    // eslint-disable-next-line no-unused-vars
    async addFileToHometaskStore({ commit }, {
      hometaskId, file, image_id,
    }) {
      const formData = new FormData()
      // formData.append('type', type)
      formData.append('src', file)
      formData.append('home_task_id', hometaskId)
      formData.append('image_id', image_id)

      // eslint-disable-next-line camelcase
      await axiosIns.post('/home-task-store/create-with-image', formData)
    },
  },
}
