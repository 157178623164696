import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    subscriptions: [],
    plans: [],
  },
  getters: {
    get_subscriptions: state => state.subscriptions,
    get_plans: state => state.plans,
  },
  mutations: {
    SET_SUBSCRIPTIONS: (state, subscriptions) => {
      state.subscriptions = subscriptions
    },
    SET_PLANS: (state, plans) => {
      state.plans = plans
    },
  },
  actions: {
    async getPlans({ commit }) {
      const data = await axiosIns.get('/tariff/index')
      const plans = data.items
      commit('SET_PLANS', plans)
    },
    async subscribeToPlan({ commit }, planId) {
      const sub = await axiosIns.post('/order/create', {
        tariff_id: planId,
      })
      commit('SET_ACTIVE_SUBSCRIPTION', sub)
    },
    async getSubscriptions({ commit }) {
      const data = await axiosIns.get('/subscription/index?expand=tariff')
      const subs = data.items
      commit('SET_SUBSCRIPTIONS', subs)
    },
    async renewSubscription({ commit }, subscription) {
      const sub = await axiosIns.post(`/user/subscriptions/${subscription.id}/renew`, subscription)
      commit('SET_ACTIVE_SUBSCRIPTION', sub)
    },
    async cancelSubscription({ commit }) {
      await axiosIns.get('/user/subscriptions/active/cancel')
      commit('SET_ACTIVE_SUBSCRIPTION', null)
    },
  },
}
