import Vue from 'vue'
import useJwt from '@/auth/jwt/useJwt'
import { abilities } from '@/libs/acl/ability'
import { initialAbility } from '@/libs/acl/config'
import axiosIns, { axiosLazyIns } from '@/libs/axios'
import { getUserFullName } from '@/helpers'

export const userRoles = {
  1: 'Учитель', 2: 'Ученик', 3: 'Не пойми кто', 100: 'Администратор',
}

export const teacher = 1
export const student = 2
export const admin = 100

export const teacherRole = userRoles['1']
export const studentRole = userRoles['2']
export const adminRole = userRoles['100']

export default {
  namespaced: true,
  state: {
    user: null,
    profile: null,
    balance: null,
  },
  getters: {
    get_user: state => state.user,
    get_user_full_name: state => {
      const { user } = state
      return getUserFullName(user)
    },
    get_profile: state => state.profile,
    get_balance: state => state.balance,
  },
  mutations: {
    SET_USER: (state, user) => {
      state.user = user
    },
    UPDATE_USER: (state, user) => {
      state.user = {
        ...state.user,
        ...user,
      }
    },
    SET_PROFILE: (state, profile) => {
      state.profile = profile
    },
    SET_BALANCE: (state, balance) => {
      state.balance = balance
    },
  },
  actions: {
    async setUser({ commit }, { user, accessToken = null, refreshToken = null }) {
      commit('SET_USER', user)

      if (accessToken) {
        useJwt.setToken(accessToken)
      }
      if (refreshToken) {
        useJwt.setRefreshToken(refreshToken)
      }
      Vue.prototype.$ability.update(abilities[String(user.role)])
      localStorage.setItem('role', userRoles[String(user.role)])
    },
    async updateUserProfile({ commit }, profile) {
      const userProfile = { ...profile }
      commit('SET_PROFILE', userProfile)
      commit('UPDATE_USER', userProfile)
    },
    async removeUser({ commit }) {
      commit('SET_PROFILE', null)
      commit('SET_USER', null)
      useJwt.removeToken()
      useJwt.removeRefreshToken()
      Vue.prototype.$ability.update(initialAbility)
      localStorage.removeItem('role')
    },
    async getProfile({ commit }) {
      const profile = await axiosIns.get('/user/current-profile')
      const userProfile = { ...profile }

      userProfile.image = null
      if (userProfile.image_id) {
        try {
          const { url } = await axiosLazyIns.storeGet(userProfile.image_id)
          userProfile.image = url
        } catch (e) {
          userProfile.image = null
        }
      }
      commit('SET_PROFILE', userProfile)
    },
    async getBalance({ commit }) {
      const amount = await axiosIns.get('/balance/current-user')
      commit('SET_BALANCE', amount)
    },
  },
}
