// sessions statuses
export const SESSION_STATUS_ACTIVE = 'going_on'
export const SESSION_STATUS_ACTIVE_LABEL = 'Ожидает'

export const SESSION_STATUS_PASSED = 'is_over'
export const SESSION_STATUS_PASSED_LABEL = 'Завершен'

export const SESSION_STATUS_ONLINE = 'online'
export const SESSION_STATUS_ONLINE_LABEL = 'Онлайн'

export const SESSION_STATUS_RESCHEDULED = 'rescheduled'
export const SESSION_STATUS_RESCHEDULED_LABEL = 'Перенесен'

export const SESSION_STATUS_CANCELED = 'canceled'
export const SESSION_STATUS_CANCELED_LABEL = 'Отменен'

// websockets channels

export const WEBSOCKETS_CHANNEL_SESSIONS = 'sessions'

// hometasks statuses
export const HOMETASK_STATUS_INACTIVE = 'inactive'
export const HOMETASK_STATUS_INACTIVE_LABEL = 'Ожидает'

export const HOMETASK_STATUS_ASSIGNED = 'assigned'
export const HOMETASK_STATUS_ASSIGNED_LABEL = 'Назначено'

export const HOMETASK_STATUS_ACTIVE = 'active'
export const HOMETASK_STATUS_ACTIVE_LABEL = 'К выполнению'

export const HOMETASK_STATUS_CHECK = 'ready_to_check'
export const HOMETASK_STATUS_CHECK_LABEL = 'Ожидает проверки'

export const HOMETASK_STATUS_DONE = 'done'
export const HOMETASK_STATUS_DONE_LABEL = 'Выполнено'

// leads
export const LEAD_STATUS_ACTIVE = 'active'
export const LEAD_STATUS_ACTIVE_LABEL = 'Активна'

export const LEAD_STATUS_DONE = 'processed'
export const LEAD_STATUS_DONE_LABEL = 'Обработана'
