<template>
  <div>
    <b-modal
      v-model="display"
      :title="isStudent ? 'Учитель начал урок' : 'Вас пригласили в видеочат'"
      hide-footer
      centered
      no-body
      body-class="p-0"
      no-close-on-backdrop
      no-close-on-esc
    >
      <template #default>
        <div class="p-2">
          <p
            v-if="isStudent"
            class="mb-0"
          >
            Учитель {{ teacherFullName }} пригласил(а) вас на урок
          </p>
          <p
            v-else-if="roomId"
            class="mb-0"
          >
            {{ isAdmin ? 'Пользователь вошел в комнату, созданную вами' : 'Администратор пригласил Вас в видеочат' }}
          </p>
        </div>
        <div class="thin-divider" />
        <div class="p-2 d-flex align-items-center justify-content-between">
          <b-button
            variant="primary"
            @click="showMore"
          >
            Перейти
          </b-button>
          <b-button
            variant="flat-secondary"
            type="button"
            @click="hide"
          >
            Скрыть
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import {
  adminRole, studentRole, teacherRole, userRoles,
} from '../../store/user'
import { getUserFullName } from '../../helpers'

const sound = require('@/assets/audio/notification.wav')

export default {
  name: 'SessionOnlineModal',
  components: {
    BModal, BButton,
  },
  data: () => ({
    display: false,
    session: null,
    roomId: null,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    isAdmin() {
      return this.userRole === adminRole
    },
    isStudent() {
      return this.userRole === studentRole
    },
    teacherFullName() {
      return getUserFullName(this.session?.teacher)
    },
  },
  methods: {
    showMore() {
      if (this.roomId) {
        this.$router.push({ name: 'cabinet.room', params: { room_id: this.roomId } })
      } else {
        this.$router.push({ name: 'cabinet.sessions.online', params: { session_id: this.session.id } })
      }
      this.hide()
    },
    async sessionOnline(sessionId) {
      this.display = false
      if (sessionId) {
        this.session = await this.$http.get(`/sessions/view?id=${sessionId}`, {
          params: {
            expand: 'teacher.usersTeachers',
          },
        })
      }
      this.showModal()
    },
    async roomOnline(roomId) {
      this.display = false
      this.roomId = roomId
      this.showModal()
    },
    showModal() {
      if (!['cabinet.sessions.online', 'cabinet.room'].includes(this.$route.name)) {
        this.display = true
        if (!document.hasFocus()) this.notify()
      }
    },
    notify() {
      const audio = new Audio(sound)
      audio.play()
    },
    hide() {
      this.display = false
    },
  },
}
</script>

<style scoped>

</style>
