<template>
  <div>
    <b-modal
      v-model="display"
      title="Не знаете с чего начать?"
      hide-footer
      centered
      no-body
      body-class="p-0"
    >
      <template #default>
        <div class="p-2">
          <p>
            Посмотрите ряд видео, в которых рассказывается об основном функционале платформы
          </p>
          <b-form-group class="mb-0">
            <b-form-checkbox
              v-model="dontShowAnymore"
              class="mr-0 mt-50"
              name="dontShowAnymore"
              inline
            >
              Больше не показывать
            </b-form-checkbox>
          </b-form-group>
        </div>
        <div class="thin-divider" />
        <div class="p-2 d-flex align-items-center justify-content-between">
          <b-button
            variant="primary"
            @click="showMore"
          >
            Посмотреть
          </b-button>
          <b-button
            variant="flat-secondary"
            type="button"
            @click="hide"
          >
            Скрыть
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BButton, BFormGroup, BFormCheckbox,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { teacherRole, userRoles } from '@/store/user'

export default {
  name: 'HelpOfferModal',
  components: {
    BModal, BButton, BFormGroup, BFormCheckbox,
  },
  data: () => ({
    display: false,
    dontShowAnymore: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
  },
  mounted() {
    setTimeout(() => {
      const helpModalDisabled = localStorage.getItem('help-modal-disabled')
      if (!helpModalDisabled) {
        this.display = true
      }
    }, 400)
  },
  methods: {
    disableModal() {
      localStorage.setItem('help-modal-disabled', true)
    },
    showMore() {
      this.$router.push({ name: 'cabinet.help' })
      this.disableModal()
      this.hide()
    },
    hide() {
      if (this.dontShowAnymore) this.disableModal()
      this.display = false
    },
  },
}
</script>

<style scoped>

</style>
