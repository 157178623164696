import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    types: [],
  },
  getters: {
    get_types: state => state.types,
  },
  mutations: {
    SET_TYPES: (state, types) => {
      state.types = types
    },
  },
  actions: {
    async getExerciseTypes({ commit }) {
      const { items } = await axiosIns.get('/exercise-types/index')
      commit('SET_TYPES', items)
    },
  },
}
