import {
  parseISO, intervalToDuration, formatDuration,
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import ru from 'date-fns/locale/ru'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Vue from 'vue'
import { createPopper } from '@popperjs/core'
import {
  LEAD_STATUS_ACTIVE,
  LEAD_STATUS_ACTIVE_LABEL,
  LEAD_STATUS_DONE,
  LEAD_STATUS_DONE_LABEL,
} from './shared/constants'

export const diffFromNow = (start, format = null) => {
  if (start === null || start === undefined) return []

  const startDate = parseISO(start)
  const duration = intervalToDuration({
    start: utcToZonedTime(startDate),
    end: Date.now(),
  })

  const formatOptions = { locale: ru, format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'] }
  if (format) formatOptions.format = format
  return formatDuration(duration, formatOptions)
}

export const diffBetweenDates = (start, end, format = null) => {
  if (start === null || start === undefined || end === null || end === undefined) return ''

  const duration = intervalToDuration({
    start: parseISO(start),
    end: parseISO(end),
  })

  const formatOptions = {
    locale: ru,
    format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'],
  }
  if (format) formatOptions.format = format
  const formatted = formatDuration(duration, formatOptions)
  if (!formatted) return 'Меньше минуты'
  if (!formatted.length) return ''
  return formatted
}

export const getUserFullName = (user, format = { surname: true, patronymic: true }) => {
  if (!user) return ''

  let fullName = user.username
  let userModel = null

  if (user.usersTeachers) userModel = user.usersTeachers
  else if (user.usersStudent) userModel = user.usersStudent

  if (userModel) {
    const { name, surname, patronymic } = userModel
    if (name) {
      fullName = `${name}`
      if (patronymic && format.patronymic) fullName += ` ${patronymic}`
      if (surname && format.surname) fullName += ` ${surname}`
    }
  }

  return fullName ? fullName.trim() : ''
}

export const getStepElementByType = (elements, type) => {
  if (!elements) return null
  let el = null
  elements.forEach(element => {
    if (element.type === type && !el && !element.disabled) {
      try {
        el = JSON.parse(element.data)
      } catch (er) {
        el = element.data
      }
    }
  })
  return el
}

export const getAllStepElementsByType = (elements, type, returnObject = false) => {
  if (!elements) return null
  const el = []
  elements.forEach(element => {
    if (element.type === type && !element.disabled) {
      const parsedEl = { ...element }
      try {
        parsedEl.data = JSON.parse(parsedEl.data)
        // eslint-disable-next-line no-empty
      } catch (er) {}
      if (returnObject) el.push(parsedEl)
      else el.push(parsedEl.data)
    }
  })
  return el
}

export const saveFilters = (router, filter) => {
  const rdyParams = {}
  Object.keys(filter).forEach(key => {
    const param = filter[key]
    rdyParams[key] = JSON.stringify(param)
  })

  router.push({
    query: {
      ...rdyParams,
    },
  }).catch(() => {})
}

export const restoreFilter = (route, filter) => {
  const params = route.query
  const fl = { ...filter }

  Object.keys(params).forEach(key => {
    const param = params[key]
    fl[key] = JSON.parse(param)
  })

  return fl
}

export const toast = ({
  title, text = null, type = 'secondary', position = 'top-right',
}) => {
  let icon = 'BellIcon'

  switch (type) {
    case 'success':
      icon = 'CheckIcon'
      break
    case 'primary':
      icon = 'InfoIcon'
      break
    case 'warning':
      icon = 'AlertTriangleIcon'
      break
    case 'danger':
      icon = 'SlashIcon'
      break

    default: { break }
  }

  Vue.prototype.$toast({
    component: ToastificationContent,
    props: {
      title,
      icon,
      text,
      variant: type,
      position,
    },
  })
}

export const withPopper = (dropdownList, component, { width }) => {
  // eslint-disable-next-line no-param-reassign
  dropdownList.style.width = width

  const popper = createPopper(component.$refs.toggle, dropdownList, {
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, -1],
        },
      },
      {
        name: 'toggleClass',
        enabled: true,
        phase: 'write',
        fn({ state }) {
          component.$el.classList.toggle(
            'drop-up',
            state.placement === 'top',
          )
        },
      },
    ],
  })
  return () => popper.destroy()
}

export const leadStatusProps = status => {
  switch (status) {
    case LEAD_STATUS_ACTIVE: {
      return { label: LEAD_STATUS_ACTIVE_LABEL, color: 'info', icon: 'ZapIcon' }
    }
    case LEAD_STATUS_DONE: {
      return { label: LEAD_STATUS_DONE_LABEL, color: 'success', icon: 'CheckIcon' }
    }
    default: {
      return { label: 'Нет данных', color: 'secondary' }
    }
  }
}

export const shuffleArray = array => {
  const shuffledArray = array
  for (let i = array.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]
  }
  return shuffledArray
}
