import { Ability } from '@casl/ability'
import { initialAbility } from './config'

const generalAbilities = [
  {
    action: 'read',
    subject: 'dashboard',
  },
  {
    action: 'read',
    subject: 'settings',
  },
  {
    action: 'read',
    subject: 'default',
  },
]

const teacherAbilities = [
  {
    action: 'read',
    subject: 'teacher',
  },
  {
    action: 'read',
    subject: 'video-stream',
  },
  // Hometasks
  {
    action: 'read',
    subject: 'hometasks',
  },
  {
    action: 'create',
    subject: 'hometasks',
  },
  {
    action: 'edit',
    subject: 'hometasks',
  },
  {
    action: 'delete',
    subject: 'hometasks',
  },
  // Ready Courses
  {
    action: 'read',
    subject: 'ready-courses',
  },
  // My Courses
  {
    action: 'read',
    subject: 'step',
  },
  {
    action: 'read',
    subject: 'courses',
  },
  {
    action: 'create',
    subject: 'courses',
  },
  {
    action: 'edit',
    subject: 'courses',
  },
  {
    action: 'delete',
    subject: 'courses',
  },
  // Exercises
  {
    action: 'read',
    subject: 'exercises',
  },
  {
    action: 'create',
    subject: 'exercises',
  },
  {
    action: 'edit',
    subject: 'exercises',
  },
  {
    action: 'delete',
    subject: 'exercises',
  },
  // Students
  {
    action: 'read',
    subject: 'students',
  },
  // Profile
  {
    action: 'read',
    subject: 'profile',
  },
  {
    action: 'edit',
    subject: 'profile',
  },
  // Plans and subscriptions
  {
    action: 'read',
    subject: 'subscriptions',
  },
  // chats
  {
    action: 'read',
    subject: 'chats',
  },
  // Leads
  {
    action: 'read',
    subject: 'leads',
  },
]

const studentAbilities = [
  {
    action: 'read',
    subject: 'student',
  },
  // Hometasks
  {
    action: 'read',
    subject: 'step',
  },
  {
    action: 'read',
    subject: 'hometasks',
  },
  {
    action: 'read',
    subject: 'video-stream',
  },
  {
    action: 'read',
    subject: 'teachers',
  },
  // Profile
  {
    action: 'read',
    subject: 'profile',
  },
  {
    action: 'edit',
    subject: 'profile',
  },
  // chats
  {
    action: 'read',
    subject: 'chats',
  },
  // interactive
  {
    action: 'read',
    subject: 'interactive',
  },
  // Leads
  {
    action: 'read',
    subject: 'lead-replies',
  },
]

const adminAbilities = [
  {
    action: 'read',
    subject: 'comments',
  },
  // ReadyCourses
  {
    action: 'read',
    subject: 'ready-courses',
  },
  {
    action: 'create',
    subject: 'ready-courses',
  },
  {
    action: 'edit',
    subject: 'ready-courses',
  },
  {
    action: 'delete',
    subject: 'ready-courses',
  },
  {
    action: 'upload',
    subject: 'courses',
  },
  // Courses
  {
    action: 'read',
    subject: 'courses',
  },
  {
    action: 'create',
    subject: 'courses',
  },
  {
    action: 'edit',
    subject: 'courses',
  },
  {
    action: 'delete',
    subject: 'courses',
  },
  // Teachers
  {
    action: 'read',
    subject: 'admin-teachers',
  },
  {
    action: 'read',
    subject: 'admin-students',
  },
  {
    action: 'read',
    subject: 'admin-subscriptions',
  },
  // Chat
  {
    action: 'read',
    subject: 'admin-chats',
  },
  // Exercises
  {
    action: 'read',
    subject: 'exercises',
  },
  {
    action: 'create',
    subject: 'exercises',
  },
  {
    action: 'edit',
    subject: 'exercises',
  },
  {
    action: 'delete',
    subject: 'exercises',
  },
  // Leads
  {
    action: 'read',
    subject: 'admin-leads',
  },

  {
    action: 'read',
    subject: 'profile',
  },
]

const teacherRules = [...generalAbilities, ...teacherAbilities, ...initialAbility]
const studentRules = [...generalAbilities, ...studentAbilities, ...initialAbility]
const adminRules = [...generalAbilities, ...adminAbilities, ...initialAbility]

export const abilities = {
  1: teacherRules,
  2: studentRules,
  3: studentRules,
  100: adminRules,
}

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userRole = localStorage.getItem('role')
const existingAbility = userRole ? abilities[userRole] : initialAbility

export default new Ability(existingAbility)
