import Centrifuge from 'centrifuge'
import { axiosChatsIns } from '@/libs/axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    client: null,
    globalMessagesSubscription: null,
    chatMessagesSubscription: null,
    chatsSubscription: null,
    roomsSubscription: null,
  },
  getters: {
    get_client: state => state.client,
    get_listening_for_chat: state => state.chatMessagesSubscription,
  },
  mutations: {
    SET_CLIENT(state, client) {
      state.client = client
    },
    SET_GLOBAL_MESSAGES_SUBSCRIPTION(state, sub) {
      state.globalMessagesSubscription = sub
    },
    SET_CHAT_MESSAGES_SUBSCRIPTION(state, sub) {
      state.chatMessagesSubscription = sub
    },
    SET_CHATS_SUBSCRIPTION(state, sub) {
      state.chatsSubscription = sub
    },
    SET_ROOMS_SUBSCRIPTION(state, sub) {
      state.roomsSubscription = sub
    },
  },
  actions: {
    async initConnection({ commit, state }) {
      if (!state.client) {
        let centrifuge = null

        const accessToken = useJwt.getToken()
        if (accessToken) {
          const subscribeEndpoint = `${process.env.VUE_APP_CHAT_API_URL}/broadcasting/auth`
          const subscribeHeaders = {
            Authorization: `Bearer ${accessToken}`,
          }
          const config = {
            subscribeEndpoint,
            disableWithCredentials: true,
            subscribeHeaders,
          }
          centrifuge = new Centrifuge(process.env.VUE_APP_CENTRIFUGO_URL, config)
          await axiosChatsIns.get('/centrifuge/token').then(async token => {
            await centrifuge.setToken(token)
            await centrifuge.connect()
            // eslint-disable-next-line no-unused-vars
          }).catch(error => {})
        } else {
          centrifuge = new Centrifuge(process.env.VUE_APP_CENTRIFUGO_URL)
          await axiosChatsIns.get('/centrifuge/temp-token').then(async token => {
            await centrifuge.setToken(token)
            await centrifuge.connect()
            // eslint-disable-next-line no-unused-vars
          }).catch(error => {})
        }

        commit('SET_CLIENT', centrifuge)
      }
    },
    disconnect({ commit, state }) {
      if (state.client) {
        state.client.disconnect()
        commit('SET_CLIENT', null)
      }
    },
    async listenForMessages({ commit, state, dispatch }, { userId, callback }) {
      await dispatch('initConnection')

      if (state.globalMessagesSubscription) state.globalMessagesSubscription.unsubscribe()

      const sub = state.client.subscribe(`$user.${userId}.messages`, message => {
        callback(message.data)
      })
      commit('SET_GLOBAL_MESSAGES_SUBSCRIPTION', sub)
    },

    async listenForChats({ commit, state, dispatch }, { userId, callback }) {
      await dispatch('initConnection')
      if (state.chatsSubscription) dispatch('unsubscribeFromChats')

      const sub = state.client.subscribe(`$user.${userId}.chats`, message => {
        callback(message.data)
      })
      commit('SET_CHATS_SUBSCRIPTION', sub)
    },
    async unsubscribeFromChats({ commit, state }) {
      if (state.chatsSubscription) state.chatsSubscription.unsubscribe()
      commit('SET_CHATS_SUBSCRIPTION', null)
    },

    async listenForChatMessages({ commit, state, dispatch }, { chatId, callback }) {
      await dispatch('initConnection')
      if (state.chatMessagesSubscription) dispatch('unsubscribeFromChatMessages')

      const sub = state.client.subscribe(`$chat.${chatId}`, message => {
        callback(message.data)
      })
      commit('SET_CHAT_MESSAGES_SUBSCRIPTION', sub)
    },
    async unsubscribeFromChatMessages({ commit, state }) {
      if (state.chatMessagesSubscription) state.chatMessagesSubscription.unsubscribe()
      commit('SET_CHAT_MESSAGES_SUBSCRIPTION', null)
    },

    async listenForSessions({ commit, state, dispatch }, { callback }) {
      await dispatch('initConnection')
      if (state.roomsSubscription) dispatch('unsubscribeFromSessions')

      const sub = state.client.subscribe('$rooms', message => {
        callback(message.data)
      })
      commit('SET_ROOMS_SUBSCRIPTION', sub)
    },
    async unsubscribeFromSessions({ commit, state }) {
      if (state.roomsSubscription) state.roomsSubscription.unsubscribe()
      commit('SET_ROOMS_SUBSCRIPTION', null)
    },
  },
}
